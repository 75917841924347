import { createAction, props } from '@ngrx/store';
import { ServiceResponse } from '@connected/data-access/fleet-service';

export namespace DashboardActions {
  export const LoadService = createAction(
    '[Dashboard] LOAD_EQUIPMENT_SERVICE',
    props<{ payload: string }>(),
  );
  export const LoadServiceSuccess = createAction(
    '[Dashboard] LOAD_EQUIPMENT_SERVICE_SUCCESS',
    props<{
      service?: ServiceResponse | null;
    }>(),
  );
  export const LoadServiceError = createAction(
    '[Dashboard] LOAD_EQUIPMENT_SERVICE_ERROR',
  );
}
