import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ServiceService } from '@connected/data-access/fleet-service';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DashboardActions } from './dashboard.actions';

@Injectable()
export class DashboardEffects {
  private actions$ = inject(Actions);

  loadService$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.LoadService),
      switchMap((action) =>
        this.serviceService
          .getServiceByEquipmentNumber(String(action.payload))
          .pipe(
            map((data) =>
              DashboardActions.LoadServiceSuccess({
                service: data.service,
              }),
            ),
            catchError(() => [DashboardActions.LoadServiceError()]),
          ),
      ),
    ),
  );

  constructor(private serviceService: ServiceService) {}
}
