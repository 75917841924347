import { createReducer, on } from '@ngrx/store';
import { ServiceResponse } from '@connected/data-access/fleet-service';
import { DashboardActions } from './dashboard.actions';

export interface DashboardState {
  service?: ServiceResponse | null;
  isServiceLoading: boolean;
  hasServiceFailed: boolean;
}

export const initialState: DashboardState = {
  service: undefined,
  isServiceLoading: true,
  hasServiceFailed: false,
};

const _dashboardReducer = createReducer(
  initialState,
  on(DashboardActions.LoadService, (state) => ({
    ...state,
    service: undefined,
    isServiceLoading: true,
    hasServiceFailed: false,
  })),
  on(DashboardActions.LoadServiceSuccess, (state, { service }) => ({
    ...state,
    service: service,
    isServiceLoading: false,
    hasServiceFailed: false,
  })),
  on(DashboardActions.LoadServiceError, (state) => ({
    ...state,
    service: undefined,
    isServiceLoading: false,
    hasServiceFailed: true,
  })),
);

export function dashboardReducer(state, action) {
  return _dashboardReducer(state, action);
}
