import { createSelector } from '@ngrx/store';
import { OperatorMonitorState } from '../';

const selectDashboard = (state: OperatorMonitorState) => state.dashboard;

export namespace DashboardSelectors {
  export const getService = createSelector(
    selectDashboard,
    (state) => state.service,
  );

  export const getIsServiceLoading = createSelector(
    selectDashboard,
    (state) => state.isServiceLoading,
  );

  export const getHasServiceFailed = createSelector(
    selectDashboard,
    (state) => state.hasServiceFailed,
  );
}
